@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arimo:wght@400;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --font: #444;
  --font-hover: #388da7;
  --font-disabled: #bbb;
  --font-header: #222;
}

html {
  scroll-behavior: smooth;
  font-family: "Inter", Helvetica, sans-serif;
}

body {
  width: 100%;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.remove-scroll {
  overflow-y: hidden;
}
